import React, { FunctionComponent, useEffect, useState } from 'react';

interface Props {
  score: number;
  show: boolean;
}

const ResultBar: FunctionComponent<Props> = ({ score, show }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimate(true), 1000);
  }, [show]);

  function getBarOffset() {
    return `${Math.round((100 - (16.67 * score))) * -1}%`;
  }

  function getBackgroundColor() {
    if (score === 1 || score === 2) {
      return '#FF4242';
    }

    if (score === 3) {
      return 'orange';
    }

    if (score === 4) {
      return '#FFC629';
    }

    if (score === 5) {
      return 'rgba(21, 161, 21, 0.5)';
    }

    if (score === 6) {
      return '#15A115';
    }

    return 'blue';
  }

  return (
    <div className="border-2 border-black h-6 overflow-hidden relative rounded-full w-full">
      <div
        className="absolute inset-0 rounded-full transform -translate-x-full transition-all"
        style={{
          backgroundColor: getBackgroundColor(),
          transform: animate ? `translateX(${getBarOffset()})` : '',
        }}
      />
    </div>
  );
};

export default ResultBar;
